export enum ChangeDemandStateType {
  All = "",
  New = "new",
  Accepted = "accepted",
  Decline = "declined",
}

export interface IChangeDemandItem {
  id: number;
  clientId: number;
  clientName: string;
  createdAt: Date;
  finishedAt: Date;
  state: ChangeDemandStateType;
  note: string;
  adminName: string;
}

export interface IAddress {
  id: number;
  street: string | null;
  houseNumber: string | null;
  landRegistryNumber: string | null;
  city: string | null;
  postCode: string | null;
  clientAddressPermanent: string | null;
  clientAddressActual: string | null;
  clientAddressMail: string | null;
}

export interface IChangeDemandDetail {
  id: number;
  clientDemandId: number;
  firstName: string;
  lastName: string;
  maidenName: string;
  title: string | null;
  degree: string | null;
  mobilePhone: string | null;
  phone: string | null;
  email: string;
  dataBox: string | null;
  identityType: "identityCard" | null;
  identityCode: string | null;
  birthDate: string;
  createdAt: string;
  finishedAt: string | null;
  clientDemandFiles: IClientDemandFile[] | null;
  clientId: number;
  permanentAddress: IAddress;
  actualAddress: IAddress;
  mailAddress: IAddress;
  addressActualIsSame: boolean;
  addressMailIsSame: boolean;
  healthInsurance: string | null;
  fullRegistration: boolean;
  clientDemandState: ChangeDemandStateType;
}

export interface IClientDemandFile {
  id: number;
  clientDemandId: number;
  fileName: string;
  fileUrl: string;
  uploadedAt: string;
  size: number;
}
